<template>
  <div>
    <el-dialog title="添加规则" width="30%" v-model="dialogVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="150px" class="ruleForm">
            <el-form-item label="触发关键词" prop="data.Keywords">
                <el-input v-model="ruleForm.data.Keywords" placeholder="请填写" ></el-input>
                <p>多个关键词请用“，”分割，多个关键词之间为“或者”的关系</p>
            </el-form-item>
            <el-form-item label="需触发的人数" prop="data.MemberCount">
                <el-input v-model="ruleForm.data.MemberCount" placeholder="请填写" @change="changeCount"  oninput="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="群标签" prop="data.TagIDs">
                <div class="cell">
                    <div class="selected">已选择(<span>{{ruleForm.data.TagIDs.length || 0}}</span>)个</div>
                    <el-button type="primary" style="margin-left:10px" @click="changeTagAction">选 择</el-button>
                </div>
            </el-form-item>
        </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
         <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>        </div>
      </template>
    </el-dialog>
    <GroupFieldCop ref="groupFieldRef" @onConfirm="sureAction" />
  </div>
</template>

<script>
import { onMounted, reactive, ref, unref } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import {UpdateTag,UpdateGroupIDsToTag} from "@/helper/tag"
import GroupFieldCop from "@/components/GroupFieldCop";

export default {
  components: {GroupFieldCop},
  setup(props, context) {
    const dialogVisible = ref(false);
    const isAdd = ref(true);
    const ruleFormRef = ref(null);
    let defaultFrom = {
        Keywords:"",
        MemberCount:"",
        TagIDs:[]
    }
    const ruleForm = reactive({
        data:JSON.parse(JSON.stringify(defaultFrom))
    });

    const rules = reactive({
        data:{
            Keywords:[{ required: true, message: "请填写关键词", trigger: "blur" }],
            MemberCount:[{ required: true,validator: validateCount, trigger: "blur" }],
            TagIDs:[{ type: "object",validator: validateTags,required: true}],
        }
    })

    function validateCount(rule,value,callback){
        console.log(ruleForm.data.MemberCount);
         if(!ruleForm.data.MemberCount){
            callback(new Error("请填写需触发的人数"));
        } else {
            callback();
        }
    }

    function validateTags(rule, value, callback){
        if(ruleForm.data.TagIDs&&ruleForm.data.TagIDs.length<=0){
            callback(new Error("请选择群标签"));
        } else {
            callback();
        }
    }

    const groupFieldRef = ref(null)

    function initCop(item) {
      dialogVisible.value = true;
      resetData()
      loadData(item)
    }

    function loadData(item){
        if(item){
            isAdd.value = false;
            ruleForm.data = JSON.parse(JSON.stringify(item))
        }
    }

    function resetData(){
        ruleForm.data =JSON.parse(JSON.stringify(defaultFrom));
        isAdd.value = true
    }

    function changeCount(val) {
        console.log(val);
        ruleForm.data.MemberCount = val
    }

    /****************点击事件********************/
    //选中的标签
    function changeTagAction(){
        groupFieldRef.value.initCop(2,ruleForm.data.TagIDs)
    }

    //确定标签
    function sureAction(radio,arr){
        ruleForm.data.TagIDs = arr
        console.log(ruleForm.data.TagIDs);
    }

    //提交
    const submitForm = async ()=>{
        const form = unref(ruleFormRef);
        if (!form) return
        try{
            await form.validate()
            dialogVisible.value = false;
            let obj = {
                Keywords:ruleForm.data.Keywords,
                MemberCount:parseInt(ruleForm.data.MemberCount),
                TagIDs:ruleForm.data.TagIDs
            }
            context.emit("onConfirm",isAdd.value,obj)
          
        }catch(error){
            console.log(error)
        }
    }


    return {
      dialogVisible,
      initCop,
      ruleFormRef,
      ruleForm,
      rules,
      changeTagAction,
      submitForm,
      groupFieldRef,
      resetData,
      sureAction,
      loadData,
      changeCount
    };
  },
};
</script>

<style lang="scss" scoped>
.ruleForm {
//   width: 700px;
  margin: 30px 40px;
  p {
    font-size: 12px;
    color: #999;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
  }
}

span {
    color: $color-common;
}

.cell{
    display: flex;
}

:deep().el-input--mini{
    width: 300px;
}
</style>
<template>
  <div class="container-wrap">
    <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="ruleForm">
        <el-form-item label="任务名称" prop="data.TaskName">
            <el-input v-model="ruleForm.data.TaskName" placeholder="请输入任务名称" show-word-limit :maxlength="20" :disabled="Status > 0"></el-input>
        </el-form-item>
        <el-form-item label="规则" prop="data.rule">
            <el-button type="primary" @click="addRuleAction" :disabled="Status > 0">添 加</el-button>
            <el-table :data="ruleForm.data.List" style="width: 100%;margin-top:10px" border>
                 <el-table-column label="触发关键词" prop="Keywords"></el-table-column>
                 <el-table-column label="需触发的人数" prop="MemberCount"></el-table-column>
                 <el-table-column label="执行的群标签" prop="TagIDs">
                    <template #default="scope">
                        <div class="cell" style="cursor: pointer;">
                            <el-tag style="margin-right:10px;" type="success" v-for="(item,index) in scope.row.TagIDs" :key="index">{{item.TagName}}</el-tag>
                        </div>
                    </template>
                 </el-table-column>
                 <el-table-column label="操作">
                    <template #default="scope">
                        <el-button type="primary" @click="editAction(scope.row,scope.$index)" :disabled="Status > 0">编 辑</el-button>
                        <el-button type="primary" @click="delAction(scope.$index)" :disabled="Status > 0">删 除</el-button>
                    </template>
                 </el-table-column>
            </el-table>
        </el-form-item>
        <el-form-item label="时间范围" prop="data.date">
             <el-date-picker :disabled="Status > 0" v-model="ruleForm.data.date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" ></el-date-picker>
            <div>
                <p>1、到达结束时间后，开始执行打群标签任务</p> 
                <p>2、当设置过往的时间，则创建后立即开始打标签任务</p>
                <p>注意：当一个群满足多个规则时，只会执行触发人数最多的那条规则</p>
            </div>
        </el-form-item>
    </el-form>
    <div class="foot-wrap">
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        <el-button @click="cancelAction">取 消</el-button>
    </div>
    <TagsRuleCop ref="tagsRuleRef" @onConfirm="sureAction"/>
  </div>
</template>

<script>
import {unref, reactive,ref,onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox,ElMessage } from 'element-plus';
import TagsRuleCop from "@/components/GroupManager/TagsRuleCop"
import {GroupTagCreateTask,GroupTagTaskInfo,GroupTagUpdateTask} from "@/helper/tag"
import _ from "lodash"
import Tool from '@/assets/js/tools'

export default {
  name: "AddTagRule",
  components: {TagsRuleCop},
  setup() {
    const router = useRouter();
    const SID = useRoute().query.SID;
    const Status = useRoute().query.Status;
    const ruleFormRef = ref(null);
    let defaultForm={
        TaskName:"",
        List:[],
        date:""
    }
    const ruleForm = reactive({
      data:JSON.parse(JSON.stringify(defaultForm)) 
    });

    const rules = reactive({
        data:{
            TaskName: [{ required: true, message: "请填写任务名称", trigger: "blur" }],
            date: [{type: "object",validator: validateDate,required: true,message: "请选择有效时间"}],
            rule:[{type: "object",validator: validateRule,required: true}]
        }
    })

    const tagsRuleRef = ref(null)

    function validateDate(rule, value, callback) {
      if (ruleForm.data.date == '') {
        callback(new Error("请选择有效时间"));
      } else {
        callback();
      }
    }

    function validateRule(rule, value, callback){
        if (ruleForm.data.List&&ruleForm.data.List.length<=0) {
            callback(new Error("请添加规则"));
        } else {
            callback();
        }
    }

    onMounted(()=>{
        if(SID){
            GroupTagTaskInfo({TaskID:SID}).then(res=>{
                console.log(res);
                ruleForm.data =Object.assign({},ruleForm.data,res.List) 
                ruleForm.data.date = [Tool.GMTToStr(ruleForm.data.StartTime,2),Tool.GMTToStr(ruleForm.data.EndTime,2)]
                ruleForm.data.List = dealWithTagList(ruleForm.data.List)
            })
        }
    })

    function dealWithTagList(arr){
        let tag = []
        for(let item of arr){
            tag.push({
                Keywords:item.Keywords,
                MemberCount:item.MemberCount,
                TagIDs: item.TagList
            })
        }
        return tag
    }
    
    /******************点击事件*********************/
    let holdIndex = ref(0)

    //新建
    function addRuleAction(){
        tagsRuleRef.value.initCop()
    }

    //编辑
    function editAction(item,index){
        holdIndex.value = index
        tagsRuleRef.value.initCop(item)
    }

    //删除
    function delAction(index){
        ruleForm.data.List.splice(index,1)
    }

    //确定
    function sureAction(isAdd,item){
        console.log(item);
        if(isAdd){
            ruleForm.data.List.push(item)
        } else {
            ruleForm.data.List[holdIndex.value] = item
        }
    }

    //取消
    function cancelAction() {
      router.go(-1)
    }

    function dealWithList(){
        let arr=[]
        for(let item of ruleForm.data.List){
            console.log(item);
            arr.push({
                Keywords:item.Keywords,
                MemberCount:item.MemberCount,
                TagIDs: item.TagIDs.map(item=>item.ID ? item.ID : item.TagID)
            })
        }

        return arr
    }

    //确认
    const submitForm =_.debounce(async ()=> {
      const form = unref(ruleFormRef);
      if (!form) return;
      try {
        await form.validate();
        let params = {
            TaskName: ruleForm.data.TaskName, //任务名称
            StartTime: Tool.GMTToStr(ruleForm.data.date[0],2), //任务开始时间
            EndTime:Tool.GMTToStr(ruleForm.data.date[1],2), //任务结束时间
            List: dealWithList()
        }

        if(SID){
            params.TaskID = SID
            GroupTagUpdateTask(params).then(res=>{
                if(Status == 0){
                     ElMessage({
                        type: "success",
                        message: "修改成功",
                    });
                }
               
                    router.go(-1)
            })
        }else{
            GroupTagCreateTask(params).then(res=>{
                ElMessage({
                    type: "success",
                    message: "保存成功",
                });
                router.go(-1)
            })
        }
        
          
      } catch (error) {
        console.log(error);
      }
    }, 1000, {
        'leading': true, //在延迟开始前立即调用事件
        'trailing': false, //在延时结束后不调用,保证事件只被触发一次
    })

    return{
        ruleFormRef,
        ruleForm,
        rules,
        addRuleAction,
        submitForm,
        cancelAction,
        tagsRuleRef,
        sureAction,
        editAction,
        delAction,
        holdIndex,
        Status
    }
  }
}
</script>

<style lang="scss" scoped>
.container-wrap {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.ruleForm {
//   width: 650px;
  margin: 30px 40px;
  p {
    font-size: 12px;
    color: #999;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
    .sendtext{
        width:90px;
        margin-left: 10px;
    }
  }
}

.foot-wrap {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

:deep().el-input--mini{
    width: 360px;
}

:deep().el-table--border{
    border: 1px solid #ebeef5!important;
    border-right: none!important;
}
</style>